import React from 'react';
import { useTranslation } from 'react-i18next';

const LinkDemo = () => {
  const [t] = useTranslation();
  return (
    <div className="linkDemo">
        <a href="https://marvelapp.com/407f3j1" target="_blank" rel="noopener noreferrer">{t('try_the_demo_version')}</a>
    </div>
  );
};

export default LinkDemo;
