import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

const options = [
  { value: 'pl', label: 'Polska' },
  { value: 'en', label: 'English' },
];

class ChangeLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: options[this.corectLanguage()],
    };
  }
  corectLanguage = () => {
    let optionsKey = 0;
    options.forEach((item, key) => {
      optionsKey = (this.props.i18n.language === item.value) && key;
    });
    return optionsKey;
  }
  changeLang = (language) => {
    const { i18n } = this.props;
    const { value } = language;
    this.setState({
      language,
    });
    i18n.changeLanguage(value);
  }
  render() {
    const { language } = this.state;
    return (
      <div className="changeLanguage">
        <Select
          defaultValue={options[0]}
          options={options}
          value={language}
          onChange={this.changeLang}
          className="App-Select"
        />
      </div>
    );
  }
};

export default withTranslation('translations')(ChangeLanguage);
