import React, { Component } from 'react';
import './App.sass';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import Benefits from './components/Benefits';
import HowItIsWorking from './components/HowItIsWorking';
import Error from './components/Error';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

import Police from './components/Police';
import Cookies from './components/Cookies';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <main>
          <Navigation />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/benefits" component={Benefits} />
            <Route path="/howItIsWorking" component={HowItIsWorking} />
            <Route path="/police" component={Police} />
            <Route path="/cookies" component={Cookies} />
            <Route component={Error} />
          </Switch>
          <Footer />
        </main>
      </BrowserRouter>
    );
  }
}

export default App;
