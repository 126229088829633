import React from 'react';

const Police = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 policy">
            <h3 className="my-5">Polityka prywatności IT MANAGER</h3>
            <p>Administratorem danych osobowych jest Prime Bit Games Spółka Akcyjna z siedzibą w Rzeszowie, ul. Słowackiego 24/1107, 35-060 Rzeszów, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Rzeszowie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000693763, NIP: 8133733409, REGON: 366104954, wysokość kapitału zakładowego: 642 673,30 złotych.</p>
            <p>Kontakt z Administratorem danych może odbywać się w formie tradycyjnej na adres Prime Bit Games S.A. lub pocztą elektroniczną na adres <a href="mailto:office@primebitgames.com">office@primebitgames.com</a>.</p>
            <p>Pracownicy przedstawieni przez Pracodawcę pozostają anonimowi. Wyjątkiem jest przedstawienie zdjęcia danego Pracownika przez Pracodawcę, na co zobowiązany jest uzyskać wcześniej odpowiednią zgodę.</p>
            <p>Do prawidłowego funkcjonowania IT Managera nie są potrzebne jakiekolwiek dane osobowe, jednak możliwość ich zebrania i przetwarzania w postaci zdjęć (wizerunków) powoduje, iż Prime Bit Games S.A. dokłada należytej staranności aby dane te były bezpieczne.</p>
            <ol>
              <li>
                Definicje
                <ul>
                  <li>1.1. Konto – zbiór zasobów i ustawień utworzonych dla Użytkownika w ramach Serwisu;</li>
                  <li>1.2. Pracodawca – zgodnie z art. 3. ustawy z dnia 26 czerwca 1974 r. Kodeks pracy (t.j. Dz.U. 2018 poz. 917 ze zm.) „Pracodawcą jest jednostka organizacyjna, choćby nie posiadała osobowości prawnej, a także osoba fizyczna, jeżeli zatrudniają one pracowników”;</li>
                  <li>1.3. Pracownik – zgodnie z art. 2. Kodeksu pracy „Pracownikiem jest osoba zatrudniona na podstawie umowy o pracę, powołania, wyboru, mianowania lub spółdzielczej umowy o pracę”;</li>
                  <li>1.4. Profil – funkcjonalność Konta, pozwalająca Usługobiorcy na gromadzenie wybranych informacji związanych z jego kadrą;</li>
                  <li>1.5. Regulamin – Regulamin świadczenia usług w ramach Serwisu;</li>
                  <li>1.6. RODO – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób izycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych);</li>
                  <li>1.7. Serwis – serwis internetowy należące do Prime Bit Games S.A., służący do wymiany i korzystania z kadry (Pracowników) Użytkownika;</li>
                  <li>1.8. Usługobiorca – przedsiębiorca, posiadający Konto i korzystający z Usług świadczonych drogą elektroniczną przez Usługodawcę;</li>
                  <li>1.9. Usługodawca – Prime Bit Games S.A.;</li>
                  <li>1.10. Użytkownik – przedstawiciel Usługobiorcy obsługujący Konto.</li>
                  <li>Ewentualne inne nazwy użyte w Polityce Prywatności stosowane są zgodnie z ich definicją określoną w Regulaminie.</li>
                </ul>
              </li>
              <li>
                Ochrona danych osobowych
                <ul>
                  <li>2.1. Dla celów założenia Konta/Profilu przez Usługodawcę, przetwarzane są dane osobowe Pracodawcy lub Użytkownika (imię, nazwisko, adres e-mail, numer telefonu oraz hasło). Jest to niezbędne do wykonania umowy (podstawa prawna - art. 6 ust. 1 lit. b) RODO).</li>
                  <li>2.2. Dla realizacji Usług przetwarzane są dane udostępnione w Profilu oraz dane związane z aktywnością Usługobiorcy w Serwisie: dane urządzenia oraz systemu operacyjnego, lokalizacji, przeglądarki, sesji i ID. Udostępnienie niektórych danych jest obligatoryjne. Odmowa udostępnienia danych wymaganych obligatoryjnie skutkuje brakiem możliwości świadczenia niektórych Usług (podstawa prawna - art. 6 ust. 1 lit. b) RODO).</li>
                  <li>2.3. W celu realizacji Usługi Usługobiorca może zamieszczać zdjęcia (wizerunki) swoich Pracowników (inne dane nie są zamieszczane), aczkolwiek zamieszczenie zdjęcia (wizerunku) Pracownika wymaga jest bezwzględnej zgody. Usługobiorca zobowiązany jest taką zgodę uzyskać i oświadczyć Usługodawcy, iż dany Pracownik złożył przedmiotowe oświadczenie. Usługodawca uprawniony jest do żądania okazania mu wymaganej zgody Pracownika (podstawa prawna – art. 6 ust. 1 lit. a) RODO).</li>
                  <li>2.4. Dla sporządzania statystyk dotyczących korzystania z Serwisu i wprowadzania udoskonaleń oraz zagwarantowania bezpieczeństwa Serwisu przetwarzane są dane dotyczące aktywności w Serwisie, takie jak przeglądane Profile, czas spędzony na stronach Profili, lokalizacja, adres IP, ID oraz dane dotyczące systemu operacyjnego i przeglądarki (podstawa prawna – art. 6 ust. 1 lit. f) RODO).</li>
                  <li>2.5. Dla umożliwienia dochodzenia ewentualnych roszczeń przetwarzane są dane udostępnione w Profilu (Moje konto), takie jak: imię, nazwisko, e-mail, telefon, które są niezbędne do wykazania istnienia i wysokości szkody. Podstawą jest art. 6 ust. 1 lit. f RODO.</li>
                  <li>2.6. Dla właściwego rozpoznawania skarg, wniosków i reklamacji przetwarzane są dane udostępnione w Profilu (Moje konto), dane związane z aktywnością Usługobiorcy w Serwisie oraz dane zawarte w skardze, wniosku, reklamacji i ich załącznikach. Podstawą jest art. 6 ust. 1 lit. f RODO.</li>
                  <li>2.7. Dane osobowe przekazywane są Usługobiorcom lub Użytkownikom. Do udostępnienia danych osobowych dochodzi w przypadku korzystania z funkcjonalności Profilu – przeglądanie Profili innych Usługobiorców (zawierających dane Usługobiorcy/Użytkownika oraz zdjęcia (wizerunki) jego Pracowników). Usługobiorcy, którym udostępniane są dane osobowe samodzielnie określają cele i sposoby przetwarzania tych danych, nie podlegając w tym zakresie jakimkolwiek instrukcjom Usługodawcy.</li>
                  <li>2.8. Dane osobowe mogą być przekazywane, wyłącznie na polecenie Usługodawcy, podmiotom przetwarzającym, które świadczą usługi hostingu, analizy ruchu w Serwisie oraz wspierają określone funkcjonalności Konta/Profilu oraz administratorom, którzy działają samodzielnie, samemu ustalając cele i sposoby wykorzystania danych osobowych, prowadząc badania statystyczne.</li>
                  <li>2.9. Dane osobowe będą udostępniane na żądanie uprawnionych organów państwowych.</li>
                  <li>2.10. Dane osobowe Usługobiorcy/Użytkownika przechowywane są przez okres posiadania Konta w Serwisie dla celów realizacji usługi Konta oraz związanych z nim funkcjonalności oraz innych usług zgodnie z Regulaminem. Dane osobowe w postaci wizerunku Pracowników przetwarzane są do momentu ich usunięcia przez Usługobiorcę/Użytkownika (1), do momentu istnienia Konta (2) bądź wycofania przez nich zgody (3). Po usunięciu Konta Administrator będzie przechowywał jedynie dane identyfikacyjne przedsiębiorcy – Usługobiorcy, w celach związanych z rozpatrywaniem ewentualnych skarg bądź roszczeń związanych z korzystaniem z Usług przez okres 3 lat.</li>
                </ul>
              </li>
              <li>
                Prawa związane z przetwarzaniem danych osobowych
                <ul>
                  <li>3.1. Po stworzeniu Konta i zalogowaniu się do Serwisu istnieje możliwość dokonywanie zmian w ustawieniach Konta/Profilu. Usługobiorcy/Użytkownikowi przysługuje prawo dostępu do swoich danych oraz może żądać od Administratora niezbędnych informacji (podstawa prawna – art. 15 RODO). Usługobiorca/Użytkownik może żądać: informacji o celach przetwarzania, kategoriach przetwarzanych danych osobowych, o odbiorcach lub kategoriach odbiorców tych danych, planowanym okresie przechowywania danych lub o kryteriach ustalania tego okresu, o prawach przysługujących na mocy RODO oraz o prawie wniesienia skargi do organu nadzorczego, o źródle tych danych, o zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu oraz o zabezpieczeniach stosowanych w związku z przekazaniem tych danych poza Unię Europejską oraz kopii swoich danych osobowych.</li>
                  <li>3.2. Prawo do cofnięcia zgody (podstawa prawna - art. 7 ust. 3 RODO). Użytkownikowi/Usługobiorcy przysługuje prawo do cofnięcia każdej zgody udzielonej podczas tworzenia Konta w Serwisie i później. Cofnięcie zgody ma skutek od momentu wycofania zgody. Cofnięcie zgody nie wpływa na przetwarzanie dokonywane zgodnie z prawem przed jej cofnięciem. Cofnięcie zgody nie może uniemożliwić dalszego korzystania z Usług świadczonych za pośrednictwem Serwisu.</li>
                  <li>3.6. Prawo do przenoszenia danych (podstawa prawna - art. 20 RODO). Użytkownik/Usługobiorca ma prawo żądać otrzymania swoich danych osobowych, a następnie przesłać je do innego, administratora danych osobowych. Można również żądać, by dane osobowe zostały przesłane bezpośrednio takiemu innemu administratorowi, o ile jest to technicznie możliwe.</li>
                  <li>3.7. Prawo do sprzeciwu wobec wykorzystania danych (podstawa prawna - art. 21 RODO). Użytkownik/Usługobiorca ma prawo w dowolnym momencie wnieść sprzeciw wobec wykorzystania danych osobowych. Administratorowi nie wolno już przetwarzać tych danych osobowych, chyba że wykaże on istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec interesów, praw i wolności osoby, której dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.</li>
                </ul>
              </li>
              <li>
                Skargi i wnioski
                <p>Skargi i wnioski dotyczące przetwarzania danych osobowych oraz realizacji przysługujących uprawnień należy zgłaszać na adres poczty elektronicznej office@primebitgames.com. Każdemu czyje prawa związane z ochroną danych osobowych zostay naruszone przysługuje prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (PUODO).</p>
              </li>
              <li>
                Zmiany
                <p>Niniejsza Polityka prywatności może być zmieniana i aktualizowana. O wszelkich zmianach Usługobiorca/Użytkownik zostanie poinformowany z wyprzedzeniem.</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Police;
