import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {I18nextProvider} from 'react-i18next';
import i18n from './config/i18n';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);

function hideNavList() {
  let navLink = document.getElementsByClassName('nav-link');
  let i = navLink.length;
  while (i--) {
    navLink[i].addEventListener("click", function(){
      document.getElementById('navbar').classList.remove('show');
    });
  }
}
hideNavList();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
