import React from 'react';
import { useTranslation } from 'react-i18next';
import BenefitsIcon_1 from '../images/benefitsIcon_1.png';
import BenefitsIcon_2 from '../images/benefitsIcon_2.png';
import BenefitsIcon_3 from '../images/benefitsIcon_3.png';
import BenefitsIcon_4 from '../images/benefitsIcon_4.png';
import BenefitsIcon_5 from '../images/benefitsIcon_5.png';
import BenefitsIcon_6 from '../images/benefitsIcon_6.png';
import {Fade, Flip}  from 'react-reveal';

const Benefits = () => {
  document.getElementById('root').classList.remove('Home');
  const [t] = useTranslation();
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h3 className="my-5">
              <Flip bottom cascade>
                {t('benefitsTitle')}
              </Flip>
            </h3>
          </div>
        </div>
        <Fade bottom cascade>
          <div className="row BenefitsList mb-5">
            <div className="col-12 col-lg-6">
              <div className="BenefitsList-item">
                <img src={BenefitsIcon_1} alt="benefits icon" />
                <span>{t('benefitsItem1')}</span>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="BenefitsList-item">
                <img src={BenefitsIcon_4} alt="benefits icon" />
                <span>{t('benefitsItem2')}</span>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="BenefitsList-item">
                <img src={BenefitsIcon_2} alt="benefits icon" />
                <span>{t('benefitsItem3')}</span>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="BenefitsList-item">
                <img src={BenefitsIcon_5} alt="benefits icon" />
                <span>{t('benefitsItem4')}</span>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="BenefitsList-item">
                <img src={BenefitsIcon_3} alt="benefits icon" />
                <span>{t('benefitsItem5')}</span>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="BenefitsList-item">
                <img src={BenefitsIcon_6} alt="benefits icon" />
                <span>{t('benefitsItem6')}</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Benefits;
