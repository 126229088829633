import React, { Component } from 'react';
import ContactForm from './ContactForm';
import { withTranslation } from 'react-i18next';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      msg: 0
    }
  }
  resetMessage() {
    this.setState({
      sent: false,
      msg: 0
    });
  }
  render() {
    const { t } = this.props;
    return (
        <div className="contact">
          <p>
            <span>{t('offerDescription')}</span>
            <button onClick={() => this.resetMessage()} className="contactModal" data-toggle="modal" data-target="#contactModal"><span>{t('writeToUs')}</span></button>
          </p>

          <div className="modal fade" id="contactModal" tabIndex="-1" role="dialog" aria-labelledby="contactModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="contactModalTitle">{t('writeToUs')}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModel">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ContactForm verifySent={(sent, msg) => this.setState({sent, msg})}/>
                </div>
                {this.state.sent && this.state.msg === 1 &&
                  <div className="modal-footer">
                    <h5>{t('thankYouForContactingUs')}</h5>
                  </div>
                }
                {this.state.msg === 2 &&
                  <div className="modal-footer warning">
                    <h5>{t('please_fill_in_the_form_fields_correctly')}</h5>
                  </div>
                }
                {this.state.msg === 3 &&
                  <div className="modal-footer warning">
                    <h5>{t('serverErrorTryLater')}</h5>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
    );
  }
};

export default withTranslation('translations')(Contact);
