import React from 'react';
import { NavLink } from 'react-router-dom';
import NavList from './NavList';
import Contact from './Contact';
import ChangeLanguage from './ChangeLanguage';
import { useTranslation } from 'react-i18next';
import {Fade, Flip, Slide}  from 'react-reveal';

const Footer = () => {
  const [t] = useTranslation();
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            <h3>
              <Flip bottom cascade>
                {t('navigation')}
              </Flip>
            </h3>
            <Fade>
              <NavList />
              <ChangeLanguage />
            </Fade>
          </div>
          <div className="col-12 col-lg-4">
            <h3 className="mt-3">
              <Flip bottom cascade>
                {t('offer')}
              </Flip>
            </h3>
            <Contact />
            <Fade>
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/police">{t('privacyPolicy')}</NavLink>
                </li>
                <li>
                  <NavLink to="/cookies">{t('cookiesPolicy')}</NavLink>
                </li>
              </ul>
            </Fade>
          </div>
          <div className="col-12 col-lg-4">
            <h3>
              <Flip bottom cascade>
                {t('contact')}
              </Flip>
            </h3>
            <Fade>
              <ul className="list-unstyled">
                <li>
                  <a href="https://goo.gl/maps/XSZtaMq7TPXKKp278" target="_blank" rel="noopener noreferrer"> Szlachecka 1, os. Przybyszówka (Dworzysko), <br />35-213 Rzeszów</a>
                </li>
                <li>
                  <a href="tel:+48577654628">+48 577 654 628</a>
                </li>
                <li>
                  <a href="mailto:tdrwal@primebitgames.com">tdrwal@primebitgames.com</a>
                </li>
              </ul>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container--bottom">
        <Slide bottom cascade>
          <a href="https://primebitgames.com/" target="_blank" rel="noopener noreferrer">Prime Bit Games S.A.</a>
        </Slide>
      </div>
    </footer>
  );
};

export default Footer;
