import React from 'react';

const Cookies = () => {
  return (
    <section>
      <div className="container d-flex">
        <div className="row my-auto">
          <div className="col-12 cookies">
            <h3 className="my-5">Polityka ciasteczek (COOKIES)</h3>
            <ol>
              <li>Polityka cookies ma zastosowanie do Serwisu.</li>
              <li>Pojęcia pisane wielką literą mają znaczenie określone w Polityce Prywatności oraz w niniejszym dokumencie.</li>
              <li>Pliki cookies to pliki tekstowe, zapisywane przez przeglądarkę internetową na dysku komputera lub innego urządzenia, w celu przechowywania informacji służących do identyfikacji lub zapamiętywania historii działań podejmowanych w Serwisach.</li>
              <li>Serwis używa jedynie plików cookies sesyjnych (usuwane jednocześnie z zamknięciem przeglądarki internetowej).</li>
              <li>
                Pliki cookies używane są w następujących celach:
                <ul>
                  <li>5.1. świadczenie żądanych usług;</li>
                  <li>5.2. optymalizacji korzystania z Serwisu;</li>
                  <li>5.3. prowadzenia statystyki wyświetleń stron i podstron Serwisu;</li>
                  <li>5.4. śledzenia aktywności w Serwisie;</li>
                </ul>
              </li>
              <li>Istnieje możliwość określenia warunków przechowywania lub uzyskiwania dostępu do plików cookies za pomocą ustawień przeglądarki internetowej.</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cookies;
