import React from 'react';
import { useTranslation } from 'react-i18next';
import {Fade, Flip}  from 'react-reveal';
import ScreenUp from '../images/screen_up.png';
import ScreenDown from '../images/screen_down.png';
import LinkDemo from './LinkDemo';

const Home = (props) => {
  document.getElementById('root').classList.add('Home');
  const [t] = useTranslation();
  return (
      <section className="home">
        <div className="container">
        <Fade>
          <div className="row home__row">
            <div className="col-12 col-lg-5 ml-auto">
              <h3>
                  <Fade>
                    {t('homeTitle1half1')}
                  </Fade>
                  <Fade delay={50}>
                    {t('homeTitle1half2')}
                  </Fade>
              </h3>
              <Fade delay={100}>
                <p>
                  {t('homeDescription1')}
                </p>
                <LinkDemo />
              </Fade>
            </div>
          </div>
          </Fade>
          <div className="row home-textBox">
            <div className="col-12">
              <h3 className="my-3">
                <Flip bottom cascade>
                  {t('homeTitle2')}
                </Flip>
              </h3>
              <Fade bottom>
                <p className="mb-5">{t('homeDescription2')}</p>
              </Fade>
              <h3 className="mb-3">
                <Flip bottom cascade>
                  {t('homeTitle3')}
                </Flip>
              </h3>
              <Fade bottom>
                <p className="mb-5">{t('homeDescription3')}</p>
              </Fade>
            </div>
            <div className="col-12">
              <Fade cascade delay={500}>
                <div className="screens">
                  <img src={ScreenUp} className="img-fluid" alt="Screens" />
                  <img src={ScreenDown} className="img-fluid" alt="Screens" />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Home;
