import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import pl from '../locale/pl';
import en from '../locale/en';
const lng = navigator.languages.includes('pl') ? 'pl' : 'en';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator'],
    },
    lng: lng,
    resources: { pl, en },
    fallbackLng: lng,
    // debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
    	escapeValue: false,
    	formatSeparator: ',',
    },
    react: {
    	wait: true,
    },
  });

export default i18n;
