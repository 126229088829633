import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavList = () => {
  const [t] = useTranslation();
  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <NavLink to="/" exact className="nav-link" activeClassName="active">Home</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/HowItIsWorking" className="nav-link" activeClassName="active">{t('howItIsWorking')}</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/Benefits" className="nav-link" activeClassName="active">{t('benefits')}</NavLink>
      </li>
      <li className="nav-item nav-item--dashboard">
        <a className="nav-link" href="//dashboard-itmanager.primebitstudio.com" target="_blank" rel="noopener noreferrer">{t('log_in')}</a>
      </li>
    </ul>
  );
};

export default NavList;
