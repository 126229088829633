import React from 'react';
import { NavLink } from 'react-router-dom';
import NavList from './NavList';
import logo from '../images/logo.svg';

const Navigation = () => {
  return (
    <nav className="navbar fixed-top navbar-dark navbar-expand-lg">
      <div className="container">
        <div className="row">
          <div className="col-12 d-lg-flex">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <NavLink to="/" exact className="navbar-brand">
              <img src={logo} alt="App-logo" className="img-fluid" />
            </NavLink>
            <div className="collapse navbar-collapse" id="navbar">
              <NavList />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
