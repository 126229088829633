import React from 'react';
import { useTranslation } from 'react-i18next';

const Error = () => {
  const [t] = useTranslation();
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="my-5">..{t('pathDoesNotExist')}!</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
