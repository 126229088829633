import React from 'react';
import DeviceAll from '../images/device_all.png';
import { useTranslation } from 'react-i18next';
import {Fade, Flip}  from 'react-reveal';
import LinkDemo from './LinkDemo';

const HowItIsWorking = () => {
  document.getElementById('root').classList.remove('Home');
  const [t] = useTranslation();
  return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="my-5">
                <Flip bottom cascade>
                  {t('howItIsWorking')}
                </Flip>
              </h3>
              <Fade bottom>
                <p>
                  {t('howItIsWorkingDescription')}
                </p>
              </Fade>
              <Fade delay={500}>
                <img src={DeviceAll} className="img-fluid mt-5" alt="DeviceAll" />
              </Fade>
            </div>
          </div>
        </div>
        <div className="HowItIsWorking">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 HowItIsWorking-item">
                <h3 className="my-5">
                  <Flip bottom cascade>
                    {t('howItIsWorkingTitle1')}
                  </Flip>
                </h3>
                <Fade bottom>
                  <p className="mb-5">{t('howItIsWorkingDescription1')}</p>
                </Fade>
              </div>
              <div className="col-12 col-lg-6">
                <h3 className="my-5">
                  <Flip bottom cascade>
                    {t('howItIsWorkingTitle2')}
                  </Flip>
                </h3>
                <Fade bottom>
                  <p>{t('howItIsWorkingDescription2')}</p>
                  <LinkDemo />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default HowItIsWorking;
