import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  message: '',
  mailSent: false,
  error: null
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  handleFormSubmit = event => {
    event.preventDefault();
    if (this.validateForm(this.state)) {
      axios({
        method: 'post',
        url: '/api/contact',
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.state
      }).then(result => {
        if (result.data.msg === 'success'){
          this.resetForm();
        }else if(result.data.msg === 'fail'){
          this.setState({
            mailSent: false,
            error: 'Message failed to send.'
          });
        }
      }).catch( error => {
        this.setState({ error: error.message });
        this.props.verifySent(false, 3);
      });
    }else {
      this.props.verifySent(false, 2);
    }
  }
  resetForm(){
      this.myFormRef.reset();
      this.setState(initialState);
      this.setState({
        mailSent: true
      });
      this.props.verifySent(true, 1);
  }
  validateForm = ({ firstname, lastname, email }) => {
    let validate = { firstname, lastname, email },
        isValidate = true,
        regExpName = /^[a-zA-Z]+$/,
        regExpEmail = /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (var key of Object.keys(validate)) {
      if (!validate[key].length > 0) {
          isValidate = false;
      }
    }
    if (!regExpName.test(String(firstname+lastname).toLowerCase())) {
      isValidate = false;
    }
    if (!regExpEmail.test(String(email).toLowerCase())) {
      isValidate = false;
    }
    return isValidate;
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <form ref={(el) => this.myFormRef = el}>
          <div className="form-group">
            <label htmlFor="firstName">{t('firstname')}</label>
            <input type="text" className="form-control" id="firstName" aria-describedby="firstNameHelp" value={this.state.firstname} onChange={e => this.setState({ firstname: e.target.value })} />
          <small id="firstNameHelp" className="form-text text-muted">{t('firstNameHelp')}</small>
          </div>
          <div className="form-group">
            <label htmlFor="lastName">{t('lastname')}</label>
            <input type="text" className="form-control" id="lastName" aria-describedby="lastNameHelp" value={this.state.lastname} onChange={e => this.setState({ lastname: e.target.value })} />
          <small id="lastNameHelp" className="form-text text-muted">{t('lastNameHelp')}</small>
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('email')}</label>
            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} required/>
          <small id="emailHelp" className="form-text text-muted">{t('emailHelp')}</small>
          </div>
          <div className="form-group">
            <label htmlFor="message">{t('message')}</label>
          <textarea className="form-control" id="message" value={this.state.message} onChange={e => this.setState({ message: e.target.value })}></textarea>
          </div>
          <div className="d-flex">
            <button type="submit" className="btn btn-submit" onClick={e => this.handleFormSubmit(e)} value="Submit">{t('submit')}</button>
          </div>
        </form>
      </div>
    );
  }
};

export default withTranslation('translations')(ContactForm);
